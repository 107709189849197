/* istanbul ignore file */
import 'focus-visible';
import 'reflect-metadata';
import './styles/global.scss';

let timer = Date.now();
import(/* webpackChunkName: "core" */ './app/core')
  .then(({ bootstrap }) => bootstrap('#app'))
  .catch((error) => ({ error }))
  .then((res) => {
    const splash = document.querySelector('#splash');

    if (res && res.error) {
      const spinner = document.querySelector('.spinner');
      spinner.parentElement.removeChild(spinner);

      const errorEl = document.createElement('div');
      errorEl.classList.add('error');
      errorEl.innerHTML = res.error.message;
      splash.appendChild(errorEl);
      /* eslint-disable-next-line no-console */
      console.error(res.error);
      return;
    }

    splash.addEventListener('transitionend', () => {
      splash.parentElement.removeChild(splash);
    });

    // show splash screen for at least 500ms (remove if you don't like this behavior)
    timer = 500 - (Date.now() - timer);
    setTimeout(() => splash.classList.add('hide'), timer >= 0 ? timer : 0);
  });
